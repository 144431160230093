import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`A Fast, Open-source Visualization Tool-box`}</h1>
    <p>{`Meet ML-Dash, a beautiful, message visualization dashboard
for all of your ML needs. It works with ML-Logger to
support distributed logging from your computing platform
of choice -- be it AWS, GCE, or your school's SLURM
cluster.`}</p>
    <p>{`Both ML-Logger and ML-Dash are open-source. This web
service makes it easy to share reports and collaborate
at real-time.`}</p>
    <h2>{`Getting Started`}</h2>
    <p>{`Signup `}<a parentName="p" {...{
        "href": "/signup"
      }}>{`here`}</a></p>
    <h2>{`ML-Logger`}</h2>
    <h3>{`Fast, Distributed Logging, in <3 Seconds!`}</h3>
    <p>{`Just `}<code parentName="p" {...{
        "className": "text"
      }}>{`pip install ml-logger`}</code>{` then import. Keep all
of your message together, logging has never been so easy.`}</p>
    <h3>{`Happy Collaborators, Great Results`}</h3>
    <p>{`Dash.ml is our collaboration service that makes writing
and sharing your report easy. Connect directly to your
own, local ML-Dash instrumentation server, share with
anyone using a link.`}</p>
    <h3>{`The PAM Stack, A Modern Open-source ML Tool-chain`}</h3>
    <p>{`Reproducible research begins with open-source
infrastructure. We build the tools, so that you can
focus on research.`}</p>
    <h3>{`We Integrate with your favorite ML Frameworks!`}</h3>
    <p>{`From pyTorch, to SciKitLearn. We are working with
more frameworks to integrate ML-Logger with zero-setup.`}</p>
    <h2>{`Ready to Dive In?`}</h2>
    <p>{`1, 2, 3!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      